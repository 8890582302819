import $ from 'jquery';

import 'sylius/ui/app';
import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/sylius-api-login';
import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/sylius-api-toggle';

import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-add-to-cart';
import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-address-book';
import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-province-field';
import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-variant-images';
import '../../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-variants-prices';

$(document).ready(() => {
  // $('.popup-js').popup();

  // $('.cart.button').popup({
  //   popup: $('.cart.popup'),
  //   on: 'click',
  //   onUnplaceable() {
  //     window.location.href = $('#sylius-go-to-cart').attr('href');
  //   },
  //   silent: true,
  // });

  // $('.star.rating').rating({
  //   fireOnInit: true,
  //   onRate(value) {
  //     $('[name="sylius_product_review[rating]"]:checked').removeAttr('checked');
  //     $(`#sylius_product_review_rating_${value - 1}`).attr('checked', 'checked');
  //   },
  // });

  $('#sylius_checkout_address_customer_email').apiToggle({
    dataType: 'json',
    method: 'GET',
    throttle: 1500,

    beforeSend(settings) {
      const email = $('#sylius_checkout_address_customer_email').val();

      if (email.length < 3) {
        return false;
      }

      /* eslint-disable-next-line no-param-reassign */
      settings.data = {
        email,
      };

      return settings;
    },

    successTest(response) {
      return $('#sylius_checkout_address_customer_email').val() === response.username;
    },
  }, $('#sylius-api-login-form'));

  $('#sylius-api-login').apiLogin({
    method: 'POST',
    throttle: 500,
  });

  // $('#sylius-product-adding-to-cart').addToCart();

  $('#sylius-shipping-address').addressBook();
  $('#sylius-billing-address').addressBook();
  $(document).provinceField();
  $(document).variantPrices();
  $(document).variantImages();

  $('body').find('input[autocomplete="off"]').prop('autocomplete', 'disable');

  // $('.carousel').slick({
  //   infinite: true,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   prevArrow: $('.carousel-left'),
  //   nextArrow: $('.carousel-right'),
  //   appendArrows: false,
  // });
});
