import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-jquery';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-lightbox';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-semantic-ui';

// import 'semantic-ui-css/semantic.css';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'slick-carousel/slick/slick.css';

// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/app';
import $ from 'jquery';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/app';
import './sylius/app';

// import 'sylius/ui-resources/sass/main.scss';
// import './scss/style.scss';

import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/avatar.png';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/logo.png';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/50x50.png';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/200x200.png';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/400x300.png';

import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/img/homepage-banner.jpg';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/img/sylius-plus-banner.png';
//
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/sylius-api-login';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/sylius-api-toggle';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/app.js';
//
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-add-to-cart';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-address-book';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-province-field';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-variant-images';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/sylius-variants-prices';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/app';
//
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/logo.png';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/avatar.png';
//
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/img/homepage-banner.jpg';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/img/sylius-plus-banner.png';
//
// $(document).ready(() => {
//   $('#sylius_checkout_address_customer_email').apiToggle({
//     dataType: 'json',
//     method: 'GET',
//     throttle: 1500,
//
//     beforeSend (settings) {
//       const email = $('#sylius_checkout_address_customer_email').val();
//
//       if (email.length < 3) {
//         return false;
//       }
//
//       /* eslint-disable-next-line no-param-reassign */
//       settings.data = {
//         email,
//       };
//
//       return settings;
//     },
//
//     successTest (response) {
//       return $('#sylius_checkout_address_customer_email').val() === response.username;
//     },
//   }, $('#sylius-api-login-form'));
//
//   $('#sylius-api-login').apiLogin({
//     method: 'POST',
//     throttle: 500,
//   });
//
//   $('#sylius-product-adding-to-cart .origin').addToCart();
//
//   $('#sylius-shipping-address').addressBook();
//   $('#sylius-billing-address').addressBook();
//   $(document).provinceField();
//   $(document).variantPrices();
//   $(document).variantImages();
// });
